<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn v-if="$can('create', 'usuario')" color="success" @click="showDialog(false)">NOVO</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table :headers="grid.headers" :items="filteredItems" :loading="grid.loading"
          loading-text="Carregando... aguarde" locale="pt" class="elevation-1">
          <template v-slot:item.nome="{ item }">
            <span>{{ item.nome }}</span>
          </template>

          <template v-slot:item.usuario="{ item }">
            <span>{{ item.usuario }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon v-if="$can('edit', 'usuario')" color="green" class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon v-if="$can('delete', 'usuario')" :color="definirCorIconStatusItem(item)"
                @click="deleteItem(item)">
                {{ definirIconStatusItem(item) }}
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer v-if="$vuetify.breakpoint.lgAndUp || mobileFilter" v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp" :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown" right class="elevation-1">
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2">Filtros</v-card-title>
            <v-text-field v-model="grid.filters.nome" label="Nome" dense solo></v-text-field>
            <v-text-field v-model="grid.filters.usuario" label="CPF" dense solo></v-text-field>
            <v-text-field v-model="grid.filters.cargo" label="Cargo" dense solo></v-text-field>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isFormValid" autocomplete="off">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Nome Completo" v-model="defaultUser.nome" :rules="rules.nome"
                      :disabled="desabilarPreenchimento" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="CPF" v-model="defaultUser.usuario" :rules="rules.usuario"
                      :disabled="desabilarPreenchimento" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Email" v-model="defaultUser.email" :disabled="desabilarPreenchimento" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select v-model="defaultUser.grupoId" :items="grupos" item-text="nome" item-value="id"
                      label="Pefil" single-line :rules="rules.grupoId"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Telefone" v-model="defaultUser.telefone" :disabled="desabilarPreenchimento" required maxlength="12" :rules="rules.telefone">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleItem">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pageLoadingMixin from "mixins/page-loading.mixin";
import Validations from "services/validations/validations.js";
import notificationMixin from "mixins/notification.mixin";
import configurations from "~/commons/configurations";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";
import validations from 'services/validations/validations.js';

const MSG_ativar_SIMouNao = "Você tem certeza que deseja ativar o usuário?";
const MSG_Desativar_SIMouNao =
  "Você tem certeza que deseja desativar o usuário?";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

export default {
  name: "RegisterUser",
  mixins: [pageLoadingMixin, notificationMixin],
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  created() {
    this.getUsers();
    this.getGroupList();
  },
  data: () => ({
    editedIndex: -1,
    desabilarPreenchimento: false,
    indexUser: -1,
    dialog: false,
    isFormValid: undefined,
    isMobileFilterVisible: false,
    mobileFilter: null,
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Registro", to: "/admin/usuario" },
    ],
    grid: {
      loading: false,
      filtered: false,
      filters: {
        nome: "",
        usuario: "",
        cargo: "",
      },
      headers: [
        { text: "Id", value: "id", align: " d-none" },
        {
          text: "Nome",
          value: "nome",
          align: "start",
          sortable: false,
        },
        { text: "CPF", value: "usuario" },
        { text: "Perfil", value: "perfil" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    grupos: [],
    cleanUser: {
      id: 0,
      nomeCompleto: "",
      cpf: "",
      cargo: "",
      grupoId: 0,
      ativo: undefined,
      nomeDeUsuario: "",
      email: "",
      telefone: ""
    },
    defaultUser: {
      id: 0,
      nome: "",
      usuario: "",
      cargo: "",
      grupoId: 0,
      ativo: undefined,
      nomeDeUsuario: "",
      email: "",
      telefone: ""
    },
  }),
  methods: {
    getGroupList() {
      this.$api()
        .get(configurations.api.groups.getGroupListUri)
        .then(({ data }) => {
          this.grupos = data.resposta;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos consultar a lista de perfis.");
          }
        });
    },
    getUsers() {
      this.showPageLoading();
      this.grid.loading = true;
      this.$api()
        .get(configurations.api.user.getUserListUri)
        .then(({ data }) => {
          // console.log(data.resposta.usuarios)
          this.grid.data = data.resposta.usuarios.map((item) => {
            // console.log(item)
            return {
              id: item.id,
              nome: item.nome,
              usuario: item.usuario,
              cargo: item.cargo,
              grupoId: item.grupoId,
              perfil: item.perfil,
              ativo: item.ativo,
              email: item.email,
              telefone: item.telefone
            };
          });
        })
        .catch(({ response }) => {
          this.exibirMensagemDeRetorno(
            response,
            "Não conseguimos consultar a lista de usuários."
          );
        })
        .then(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    addUser() {
      
      delete this.defaultUser.id;
      this.defaultUser.nomeDeUsuario = this.user.username;
      this.$api()
        .post(configurations.api.user.getRegisterUserUri, this.defaultUser)
        .then(({ data }) => {
          if (data && data.usuario) {
            this.grid.data.unshift(data.usuario);
            this.notifySuccess("Usuário cadastrado com sucesso.");
            confirm("E-mail enviado ao usuário para realizar o cadastro de senha.");
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos cadastrar o usuário.");
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexUser = this.editedIndex;
      console.log(item);
      this.defaultUser = Object.assign({}, item);
      this.showDialog(true);
    },
    showDialog(desabilitarPreenchimento) {
      this.desabilarPreenchimento = desabilitarPreenchimento;
      this.dialog = true;

    },
    addNew() {
      this.addUser();
    },
    handleItem() {
      this.defaultUser.nomeDeUsuario = this.user.username;
      this.$refs.form.validate();
      if (this.isFormValid) {
        if (this.editedIndex > -1) {
          this.$api()
            .put(configurations.api.user.getUpdateUserUri, this.defaultUser)
            .then(({ data }) => {
              if (data && data.usuario) {
                Object.assign(this.grid.data[this.indexUser], data.usuario);
                this.notifySuccess("Informações atualizadas com sucesso.");
              }
            })
            .catch(({ response }) => {
              this.exibirMensagemDeRetorno(
                response,
                "Não conseguimos atualizar o usuário."
              );
            });
        } else {
          this.addNew();
        }
        this.close();
      } else this.notifyWarning("Preencha todos os campos obrigatórios");
    },
    exibirMensagemDeRetorno(response, msg) {
      if (response && response.data.mensagem) {
        this.notifyWarning(response.data.mensagem);
      } else {
        this.notifyWarning(msg);
      }
    },
    close() {
      this.dialog = false;
      this.defaultUser = Object.assign({}, this.cleanUser);
      this.editedIndex = -1;
    },
    deleteItem(item) {
      let objetoRequisicao = {
        id: item.id,
        nomeDeUsuario: this.user.username,
        ativo: !item.ativo,
      };

      var alert = confirm(
        item.ativo ? MSG_Desativar_SIMouNao : MSG_ativar_SIMouNao
      );
      if (alert) {
        this.requisicaoDeAtivarOuInativarUsuario(
          objetoRequisicao,
          item.ativo
            ? "Usuário inativado com sucesso!"
            : "Usuário ativado com sucesso!"
        );
      }
    },
    requisicaoDeAtivarOuInativarUsuario(objetoRequisicao, msgDeSucesso) {
      this.showPageLoading();
      this.$api()
        .post(configurations.api.user.getDisableUserUri, objetoRequisicao)
        .then(({ data }) => {
          if (data && data.sucesso) {
            this.notifySuccess(msgDeSucesso);
          }
        })
        .catch(({ response }) => {
          this.exibirMensagemDeRetorno(
            response,
            "Não conseguimos inativar o usuário."
          );
        })
        .then(() => {
          this.getUsers();
        });
    },
    filter() {
      if (
        this.grid.filters.nome ||
        this.grid.filters.cargo ||
        this.grid.filters.usuario
      ) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    definirIconStatusItem(item) {
      if (item.ativo) return "mdi-delete";
      return "mdi-check";
    },
    definirCorIconStatusItem(item) {
      if (item.ativo) return "red";
      return "green";
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/usuario").length === 0) {
        this.$router.push({ path: "/login" });
      }
    }
  },
  computed: {
    ...postLoginGetters([USER]),
    filteredItems() {
      return this.grid.data.filter((i) => {
        return (
          i.nome.toLowerCase().includes(this.grid.filters.nome.toLowerCase()) &&
          i.usuario.includes(this.grid.filters.usuario.toLowerCase()) &&
          i.cargo.toLowerCase().includes(this.grid.filters.cargo.toLowerCase())
        );
      });
    },
    rules() {
      return {
        nome: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 10),
        ],
        usuario: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 11),
          (value) => Validations.max(value, 14),
        ],
        grupoId: [(value) => Validations.min(value, 1)],
        telefone: [
          (value) => validations.max(value, 12),
          (value) => validations.onlyNumber(value)
        ]
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar usuário" : "Editar usuário";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}
</style>
